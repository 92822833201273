import React from 'react';

const BuyButton = ({ plan, userInfo, handleBuy, className }) => {
  return (
    <button
      onClick={() => handleBuy(plan.stripeLink)}
      className={`${className} flex items-center justify-center gap-2 transform hover:scale-105 transition-all duration-200 animate-pulse`}
    >
      <span>Subscribe Now</span>
      <svg 
        className="w-5 h-5" 
        fill="none" 
        stroke="currentColor" 
        viewBox="0 0 24 24"
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
      </svg>
    </button>
  );
};

export default BuyButton;