import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { verify, resendVerification } from './api';
import VerificationInput from './VerificationInput';
import { useTranslation } from 'react-i18next';

const VerifyEmailPage = () => {
  const { t, i18n } = useTranslation();
  const [verificationCode, setVerificationCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ message: '', type: '' });
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await verify(email, verificationCode);
      setAlert({ message: t('verify.success'), type: 'success' });
      setTimeout(() => navigate('/login'), 2000);
    } catch (error) {
      setAlert({ message: error.message || t('verify.error'), type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendVerification = async () => {
    setIsLoading(true);
    try {
      await resendVerification(email);
      setAlert({ message: t('verify.resendSuccess'), type: 'success' });
    } catch (error) {
      setAlert({ message: error.message || t('verify.resendError'), type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-teal-50 to-blue-50">
      {/* 验证表单 */}
      <div className="max-w-md mx-auto p-8 mt-12">
        <div className="bg-white/90 backdrop-blur-sm rounded-2xl p-8 shadow-xl border border-gray-100/50">
          <h2 className="text-3xl font-bold text-gray-800 mb-8 text-center">
            {t('verify.title')}
          </h2>

          {alert.message && (
            <div
              className={`mb-4 p-4 rounded-lg text-white ${
                alert.type === 'error' ? 'bg-rose-400' : 'bg-teal-400'
              }`}
            >
              {alert.message}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <VerificationInput
              onVerificationChange={setVerificationCode}
              onResend={handleResendVerification}
              isLoading={isLoading}
            />

            <button
              type="submit"
              className="w-full bg-gradient-to-r from-teal-400 to-blue-400 text-white py-3.5 rounded-xl font-semibold hover:shadow-lg transition-all disabled:opacity-70 disabled:cursor-not-allowed"
              disabled={isLoading}
            >
              {isLoading ? t('verify.processing') : t('verify.button')}
            </button>
          </form>

          <p className="mt-8 text-center text-gray-600 text-sm">
            {t('verify.tip')}{' '}
            <button
              onClick={() => navigate('/login')}
              className="bg-gradient-to-r from-teal-500 to-blue-400 bg-clip-text text-transparent font-semibold hover:opacity-80 transition-opacity"
            >
              {t('verify.loginLink')}
            </button>
          </p>
        </div>
      </div>


      
    </div>
  );
};

export default VerifyEmailPage;