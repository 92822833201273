import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserInfo, cancelSubscription } from './api';
import Alert from './Alert';
import { ShieldShaded, ArrowClockwise } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

const UserProfilePage = () => {
  const { t, i18n } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [alert, setAlert] = useState({ message: '', type: 'error' });
  const [isLoading, setIsLoading] = useState(true);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const navigate = useNavigate();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }
      try {
        const info = await getUserInfo(token);
        setUserInfo(info);
      } catch (error) {
        setAlert({ message: t('profile.fetchError'), type: 'error' });
        localStorage.removeItem('token');
        localStorage.removeItem('userInfo');
        setTimeout(() => navigate('/login'), 2000);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserInfo();
  }, [navigate, t]);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      if (!isLoading) {
        refreshUserInfo();
      }
    }, 60000); // 每分钟刷新一次

    return () => clearInterval(refreshInterval);
  }, [isLoading]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userInfo');
    navigate('/login');
  };

  const refreshUserInfo = async () => {
    const token = localStorage.getItem('token');
    try {
      const info = await getUserInfo(token);
      setUserInfo(info);
    } catch (error) {
      setAlert({ message: t('profile.refreshError'), type: 'error' });
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-teal-50 to-blue-50 flex items-center justify-center">
        <div className="animate-pulse text-gray-500">{t('profile.loading')}</div>
      </div>
    );
  }

  if (!userInfo) {
    return <div className="min-h-screen bg-gradient-to-br from-teal-50 to-blue-50">{t('profile.redirecting')}</div>;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-teal-50 to-blue-50">
      {/* 用户信息区域 */}
      <div className="max-w-3xl mx-auto p-6 mt-12">
        <div className="bg-white/90 backdrop-blur-sm rounded-2xl p-8 shadow-xl border border-gray-100/50">
          <div className="flex justify-between items-start mb-8">
            <div>
              <h2 className="text-3xl font-bold text-gray-800 mb-2">
                {t('profile.welcome')}
              </h2>
            </div>
            <button
              onClick={refreshUserInfo}
              className="bg-gray-100 hover:bg-gray-200 p-2 rounded-lg transition-colors"
              title={t('userInfo.refresh')}
            >
              <ArrowClockwise className="w-6 h-6 text-teal-500" />
            </button>
          </div>

          {alert.message && <Alert message={alert.message} type={alert.type} />}

          {/* 用户信息详情 */}
          <div className="bg-gray-50/50 rounded-xl p-6 mb-8 shadow-inner">
            <div className="space-y-6">
              <div className="space-y-2">
                <p className="text-gray-500 font-medium">{t('userInfo.email')}</p>
                <p className="text-gray-800 font-mono break-all">{userInfo.email}</p>
              </div>
              
              <div className="space-y-2">
                <p className="text-gray-500 font-medium">Subscription Status</p>
                <div className="flex items-center space-x-2">
                  {userInfo.membership ? (
                    <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-teal-100 text-teal-800">
                      <svg className="w-4 h-4 mr-1.5" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                      </svg>
                      Active Subscription
                    </span>
                  ) : (
                    <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
                      No Active Subscription
                    </span>
                  )}
                </div>
              </div>

              {userInfo.membership && (
                <div className="space-y-2">
                  <p className="text-gray-500 font-medium">Subscription Details</p>
                  <div className="bg-white rounded-lg p-4 border border-teal-100">
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <p className="text-sm text-gray-500">Start Date</p>
                        <p className="font-medium text-gray-800">
                          {new Date(userInfo.subscription_date).toLocaleDateString()}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500">Expiry Date</p>
                        <p className="font-medium text-gray-800">
                          {new Date(userInfo.subscription_expire_time).toLocaleDateString()}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500">Monthly Fee</p>
                        <p className="font-medium text-gray-800">
                          ${userInfo.monthlyFee || '9.99'}/month
                        </p>
                      </div>
                    </div>
                    <div className="mt-3 pt-3 border-t border-gray-100">
                      {userInfo.membership && (
                        <p className="text-sm text-gray-600">
                          Your subscription will expire in{' '}
                          <span className="font-medium text-teal-600">
                            {Math.ceil((new Date(userInfo.subscription_expire_time) - new Date()) / (1000 * 60 * 60 * 24))} days
                          </span>
                        </p>
                      )}
                      <button
                        onClick={() => setShowCancelModal(true)}
                        className="mt-4 w-full text-rose-600 border border-rose-200 hover:bg-rose-50 px-4 py-2 rounded-lg text-sm font-medium transition-colors"
                      >
                        Cancel Subscription
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {!userInfo.membership && (
              <div className="mt-6 bg-blue-50/50 p-4 rounded-lg border border-blue-100">
                <p className="text-sm text-gray-600">
                  <span className="font-medium text-blue-600">
                    Want unlimited access?
                  </span>{' '}
                  Subscribe to our Pro plan for unlimited AI content processing.
                </p>
                <button
                  onClick={() => navigate('/pricing')}
                  className="mt-3 text-sm text-blue-600 font-medium hover:text-blue-700"
                >
                  View Plans →
                </button>
              </div>
            )}
          </div>

          {/* 添加确认弹窗 */}
          {showCancelModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">
                  Cancel Subscription
                </h3>
                <p className="text-gray-600 mb-6">
                  Are you sure you want to cancel your subscription? This action cannot be undone.
                </p>
                <div className="flex space-x-4">
                  <button
                    onClick={() => {
                      setAlert({ 
                        message: 'Please send an email to aideleter@gmail.com to cancel your subscription. Thank you!', 
                        type: 'info'
                      });
                      setShowCancelModal(false);
                    }}
                    className="flex-1 bg-rose-600 text-white py-2 px-4 rounded-lg hover:bg-rose-700 transition-colors"
                  >
                    Yes, Cancel
                  </button>
                  <button
                    onClick={() => setShowCancelModal(false)}
                    className="flex-1 border border-gray-300 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-50 transition-colors"
                  >
                    No, Keep
                  </button>
                </div>
              </div>
            </div>
          )}

          <button
            onClick={handleLogout}
            className="w-full bg-gradient-to-r from-rose-400 to-pink-500 text-white py-3.5 rounded-xl font-semibold hover:shadow-lg transition-all"
          >
            {t('profile.logout')} →
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserProfilePage;