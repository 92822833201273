import React, { useEffect, useState } from 'react'; 
import { useNavigate, Link, Outlet, useLocation } from 'react-router-dom'; 
import { ShieldShaded } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { getUserInfo } from './api';

const Layout = ({ userInfo, setUserInfo }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // 刷新用户信息
    const refreshUserInfo = async () => {
      try {
        const response = await fetch('/api/user/info', {
          credentials: 'include'
        });
        if (response.ok) {
          const data = await response.json();
          setUserInfo(data);
        }
      } catch (error) {
        console.error('Failed to refresh user info:', error);
      }
    };

    refreshUserInfo();
  }, [location.pathname, setUserInfo]);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      {/* 导航栏 */}
      <nav className="flex items-center justify-between h-20">
        <div 
          className="flex items-center space-x-2 cursor-pointer group"
          onClick={() => navigate('/deleteai')}
        >
          <ShieldShaded className="h-8 w-8 text-teal-500 transition-colors group-hover:text-teal-600" />
          <span className="text-2xl font-bold bg-gradient-to-r from-teal-500 to-blue-500 bg-clip-text text-transparent">
            AIDeleter
          </span>
        </div>

        {/* 修改 Remove AI 导航链接 */}
        <div className="flex items-center ml-auto mr-8">
          <button
            onClick={() => navigate('/deleteai')}
            className="px-6 py-2 text-gray-600 hover:text-teal-500 font-medium transition-colors"
          >
            Remove AI Now
          </button>
        </div>

        <div className="flex items-center space-x-6">
          {userInfo ? (
            <div className="flex items-center space-x-4">
              {/* 会员状态和过期时间 */}
              <div className="flex items-center space-x-3">
                {userInfo.subscription_expire_time && (
                  <div className="flex items-center bg-white px-4 py-2 rounded-full shadow-sm">
                    <span className="flex items-center text-teal-600">
                      <svg className="w-4 h-4 mr-1.5" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                      </svg>
                      {userInfo.membership ? 'Pro Member' : 'Free User'}
                    </span>
                    <span className="mx-2 text-gray-300">|</span>
                    <span className="text-gray-600">
                      Expires: {new Date(userInfo.subscription_expire_time).toLocaleDateString()}
                    </span>
                  </div>
                )}
                <button
                  disabled={isLoading}
                  className="bg-gradient-to-r from-purple-400 to-pink-400 text-white px-4 py-2 rounded-full hover:shadow-lg transition-all flex items-center justify-center space-x-2"
                  onClick={() => navigate('/pricing')}
                >
                  <span>Subscribe Now for a Big Discount</span>
                </button>
              </div>

              <button
                onClick={() => navigate('/profile')}
                className="bg-gradient-to-r from-teal-100 to-blue-100 px-6 py-2 rounded-full hover:shadow-md transition-all"
              >
                Account
              </button>
            </div>
          ) : (
            <div className="flex space-x-4">
              <button
                className="text-gray-600 hover:text-teal-500 px-4 py-2 transition-colors"
                onClick={() => navigate('/login')}
              >
                Sign In
              </button>
              <button
                className="bg-gradient-to-r from-teal-400 to-blue-400 text-white px-6 py-2 rounded-full hover:shadow-lg transition-all"
                onClick={() => navigate('/register')}
              >
                Start Free
              </button>
            </div>
          )}
        </div>
      </nav>

      {/* 使用Outlet渲染子路由 */}
      <main className="py-4">
        <Outlet />
      </main>

      {/* 页脚 */}
      <footer className="border-t border-teal-100 mt-4 py-4">
        <div className="text-center text-sm text-gray-500 space-y-1">
          <p>Powered by Advanced AI Detection Algorithms</p>
          <p>© {new Date().getFullYear()} AIDeleter. All Rights Reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default Layout;