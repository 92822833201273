import React, { useState, useEffect } from 'react';

const VerificationInput = ({ onVerificationChange, onResend, isLoading }) => {
  const [verificationCode, setVerificationCode] = useState('');
  const [resendTimer, setResendTimer] = useState(30);

  useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [resendTimer]);

  const handleResend = () => {
    if (resendTimer > 0 || isLoading) return;
    onResend();
    setResendTimer(30);
  };

  const handleChange = (e) => {
    setVerificationCode(e.target.value);
    onVerificationChange(e.target.value);
  };

  return (
    <div className="mb-6 space-y-2">
      <label htmlFor="verificationCode" className="text-gray-700 font-medium">
        Verification Code
      </label>
      <div className="flex gap-2">
        <input
          type="text"
          id="verificationCode"
          value={verificationCode}
          onChange={handleChange}
          className="flex-1 px-4 py-3 rounded-lg border border-gray-200 bg-gray-50 focus:ring-2 focus:ring-teal-400 focus:border-transparent text-gray-800 placeholder-gray-400 transition-all"
          placeholder="Enter 6-digit code"
          required
          disabled={isLoading}
          maxLength="6"
        />
        <button
          type="button"
          onClick={handleResend}
          className={`px-6 py-3 font-semibold rounded-lg transition-all ${
            resendTimer > 0 || isLoading
              ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
              : 'bg-gradient-to-r from-teal-400 to-blue-400 text-white hover:shadow-lg'
          }`}
          disabled={resendTimer > 0 || isLoading}
        >
          {resendTimer > 0 ? `${resendTimer}s` : 'Resend'}
        </button>
      </div>
    </div>
  );
};

export default VerificationInput;