import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { LightningChargeFill, ShieldShaded, GraphUp, PatchCheck } from 'react-bootstrap-icons';

// 初始化i18n配置（需单独创建i18n.js文件）
import '../i18n';

const AiRewriterLandingPage = () => {
  const { t, i18n } = useTranslation();
  const [aiDetectionRate, setAiDetectionRate] = useState(87);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      if(aiDetectionRate > 0) {
        setAiDetectionRate(prev => Math.max(0, prev - 3));
      }
    }, 1800);
    return () => clearInterval(interval);
  }, [aiDetectionRate]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [alertCount, setAlertCount] = useState(0);

  // 动态计算警示计数
  useEffect(() => {
    const calculateAlertCount = () => {
      const now = new Date();
      const hours = now.getHours(); // 获取当前小时 (0-23)
      const minutes = now.getMinutes(); // 获取当前分钟
      // 生成更自然的随机波动
      const base = hours * 100;
      const minuteFactor = Math.round(minutes * 8); // 分钟影响系数
      return base  + minuteFactor;
    };

    // 初始计算
    setAlertCount(calculateAlertCount());
    
    // 每分钟更新一次
    const interval = setInterval(() => {
      setAlertCount(calculateAlertCount());
    }, 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <main className="min-h-screen bg-gradient-to-br from-teal-50 to-blue-50">
      <Helmet>
        <title>Bypass AI Detection | Remove AI Content | Humanize AI Content - AI Deleter</title>
        <meta name="description" content="AI Deleter provides professional paper rewriting services to bypass AI detectors by applying advanced techniques to humanize AI-generated content, effectively remove AI traces, and ensure your paper successfully passes Turnitin and other plagiarism detection systems." />
        <meta name="keywords" content="Bypass AI Detectors,humanize ai,Turnitin,remove ai,AI检测,AI降重,论文查重,降重,论文优化,学术写作" />
        <link rel="canonical" href="https://aideleter.com" />
      </Helmet>

      {/* Alert Banner */}
      <aside className="bg-rose-400 py-2 text-center text-sm font-semibold text-white" role="alert">
        🚨 {t('alert', { count: alertCount.toLocaleString() })}
      </aside>

      {/* Navigation */}
      <header>
        <nav className="flex justify-between items-center p-6 max-w-7xl mx-auto" aria-label="Main navigation">
          <div className="flex items-center space-x-2">
            <ShieldShaded className="text-teal-500 h-8 w-8" />
            <span className="text-2xl font-bold bg-gradient-to-r from-teal-500 to-blue-400 bg-clip-text text-transparent">
              AIDeleter
            </span>
          </div>
          <div className="flex items-center space-x-4">
            <a 
              href="/pricing" 
              className="text-gray-600 hover:text-teal-500 transition-colors font-medium"
              onClick={(e) => {
                e.preventDefault();
                navigate('/pricing');
              }}
            >
              {t('nav.pricing')}
            </a>
            <button
              className="text-gray-600 hover:text-gray-800 transition-colors"
              onClick={() => changeLanguage(i18n.language === 'en' ? 'zh' : 'en')}
            >
              {i18n.language === 'en' ? '中文' : 'English'}
            </button>
            <button 
              className="bg-gradient-to-r from-teal-400 to-blue-400 text-white px-6 py-2 rounded-full hover:shadow-lg transition-all"
              onClick={() => navigate('/register')}
            >
              {t('nav.unlock')} →
            </button>
          </div>
        </nav>
      </header>

      {/* Hero Section */}
      <section className="max-w-7xl mx-auto px-6 py-20" aria-labelledby="hero-heading">
        <h1 id="hero-heading" className="sr-only">AI Deleter - 专业论文AI降重服务</h1>
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          <div className="space-y-8">
            <h1 className="text-5xl font-bold text-gray-800 leading-tight">
              <span className="bg-gradient-to-r from-teal-500 to-blue-400 bg-clip-text text-transparent">
                {t('hero.titleHighlight')}
              </span>
              <br />
              {t('hero.title', { rate: aiDetectionRate })}
            </h1>
            
            {/* 动态进度条 */}
            <div className="relative pt-4">
              <div className="flex justify-between text-sm text-gray-500 mb-2">
                <span>{t('progress.risk')}</span>
                <span>{t('progress.safe')}</span>
              </div>
              <div className="h-3 bg-gray-100 rounded-full overflow-hidden">
                <div 
                  className="h-full bg-gradient-to-r from-rose-400 to-teal-400 transition-[width] duration-300 ease-out" 
                  style={{ width: `${(100 - aiDetectionRate)}%` }}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 gap-4">
              <button
                className="bg-gradient-to-r from-teal-500 to-blue-500 p-6 rounded-xl hover:scale-105 transition-transform shadow-md text-white"
                onClick={() => navigate('/register')}
              >
                <div className="text-left">
                  <p className="text-xl font-bold mb-2">Register Now</p>
                  <div className="flex items-center gap-2 text-teal-50">
                    <span className="text-sm">✨ Start Free Trial</span>
                    <svg 
                      className="w-4 h-4" 
                      fill="none" 
                      stroke="currentColor" 
                      viewBox="0 0 24 24"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

          {/* 3D流程演示 */}
          <div className="bg-gradient-to-r from-rose-100 to-blue-300 text-white rounded-2xl p-8 shadow-lg transform perspective-1000 rotateY-6 backdrop-blur-sm">
            <div className="space-y-6">
              {['aiDetection', 'aiRemove', 'semanticRewriting'].map((key) => (
                <div key={key} className="flex items-center space-x-4 p-4 bg-gray-50/80 rounded-lg">
                  <div className={`text-${t(`features.${key}.color`)}-400`}>
                    {t(`features.${key}.icon`)}
                  </div>
                  <div>
                    <h3 className="text-gray-800 font-semibold">{t(`features.${key}.title`)}</h3>
                    <p className="text-gray-500 text-sm">{t(`features.${key}.desc`)}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-8" aria-labelledby="features-heading">
        <div className="max-w-7xl mx-auto px-6">
          <h2 id="features-heading" className="text-3xl font-bold text-gray-800 mb-4">
            {t('coreAdvantages.title')}
          </h2>
          <p className="text-gray-600 mb-4">
            {t('coreAdvantages.description')}
          </p>
          <ul className="list-disc list-inside text-gray-600">
            <li>{t('coreAdvantages.benefits.benefit1')}</li>
            <li>{t('coreAdvantages.benefits.benefit2')}</li>
            <li>{t('coreAdvantages.benefits.benefit3')}</li>
            <li>{t('coreAdvantages.benefits.benefit4')}</li>
          </ul>
          <p className="text-gray-600 mt-4">
            {t('coreAdvantages.explanation')}
          </p>
          <p className="text-gray-600 mt-4 font-bold">
            {t('coreAdvantages.callToAction')}
          </p>
        </div>
      </section>

      {/* Stats Section */}
      <section className="bg-white/40 backdrop-blur-sm py-20" aria-labelledby="stats-heading">
        <div className="max-w-7xl mx-auto px-6">
          <h2 id="stats-heading" className="sr-only">Our Achievement Statistics</h2>
          <div className="grid md:grid-cols-3 gap-8 text-center">
            {['words', 'detection', 'speed'].map((key) => (
              <div key={key} className="p-6 bg-white/80 rounded-xl shadow-sm">
                <div className={`text-4xl font-bold text-${t(`stats.${key}.color`)}-500 mb-2`}>
                  {t(`stats.${key}.value`)}
                </div>
                <div className="text-gray-600">{t(`stats.${key}.label`)}</div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <aside className="max-w-7xl mx-auto px-6 py-20">
        <div className="bg-gradient-to-r from-teal-400 to-blue-400 rounded-2xl p-12 text-center shadow-lg">
          <h2 className="text-3xl font-bold text-white mb-4">{t('cta.title')}</h2>
          <p className="text-teal-50 mb-8">{t('cta.subtitle')}</p>
          <div className="flex justify-center space-x-4">
            <button 
              className="bg-white text-teal-500 px-8 py-3 rounded-full hover:bg-opacity-90 flex items-center"
              onClick={() => navigate('/invite')}
            >
              <span>{t('cta.button')} →</span>
            </button>
          </div>
        </div>
      </aside>

      {/* FAQ Section */}
      <section className="max-w-7xl mx-auto px-6 py-16 mb-20" aria-labelledby="faq-heading">
        <h2 id="faq-heading" className="text-3xl font-bold text-gray-800 mb-12">
          {t('faq.title')}
        </h2>
        
        <div className="space-y-6">
          <article className="border-b border-gray-200 pb-6">
            <h3 className="text-xl font-semibold text-gray-800 mb-3">
              {t('faq.what.question')}
            </h3>
            <div className="text-gray-600">
              {t('faq.what.answer')}
            </div>
          </article>

          <article className="border-b border-gray-200 pb-6">
            <h3 className="text-xl font-semibold text-gray-800 mb-3">
              {t('faq.process.question')}
            </h3>
            <div className="text-gray-600">
              {t('faq.process.answer')}
            </div>
          </article>

          <article className="border-b border-gray-200 pb-6">
            <h3 className="text-xl font-semibold text-gray-800 mb-3">
              {t('faq.detection.question')}
            </h3>
            <div className="text-gray-600">
              {t('faq.detection.answer')}
            </div>
          </article>

          <article className="border-b border-gray-200 pb-6">
            <h3 className="text-xl font-semibold text-gray-800 mb-3">
              {t('faq.removal.question')}
            </h3>
            <div className="text-gray-600">
              {t('faq.removal.answer')}
            </div>
          </article>

          <article className="border-b border-gray-200 pb-6">
            <h3 className="text-xl font-semibold text-gray-800 mb-3">
              {t('faq.humanize.question')}
            </h3>
            <div className="text-gray-600">
              {t('faq.humanize.answer')}
            </div>
          </article>

          <article className="border-b border-gray-200 pb-6">
            <h3 className="text-xl font-semibold text-gray-800 mb-3">
              {t('faq.best.question')}
            </h3>
            <div className="text-gray-600">
              {t('faq.best.answer')}
            </div>
          </article>
        </div>
      </section>

      {/* Footer */}
      <footer className="border-t border-teal-100">
        <div className="max-w-7xl mx-auto px-6 py-8 text-center">
          <p className="text-gray-500 text-sm">
            {t('footer.disclaimer')}<br />
            {t('footer.copyright')}
          </p>
        </div>
      </footer>
    </main>
  );
};

export default AiRewriterLandingPage;