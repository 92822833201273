import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { rephrase, getUserInfo } from './api';
import { LightningChargeFill, PatchCheck, Clipboard } from 'react-bootstrap-icons';
import { Helmet } from 'react-helmet-async';
function RephraseApp({ userInfo, setUserInfo, showToast }) {
  const [inputText, setInputText] = useState('');
  const [outputHtml, setOutputHtml] = useState('');
  const [copySuccess, setCopySuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  // 用于存储 AI 检测的概率数据
  const [probability, setProbability] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const words = inputText.trim().split(/\s+/);
    setWordCount(inputText.trim() ? words.length : 0);
  }, [inputText]);

  const handleSubmit = async (e) => { 
    e.preventDefault();
    if (!userInfo) {
      showToast('Login to try for Free!', 'error');
      navigate('/login');
      return;
    }
  
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const data = await rephrase(inputText, 'en', token);
      setOutputHtml(data.paraphrasedContent);

      const updatedUserInfo = await getUserInfo(token);
      setUserInfo(updatedUserInfo);
      localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));


      // 点击 Delete AI 后，计算随机概率并保存到状态中
      const from = Math.floor(Math.random() * 21) + 80; // 80 ~ 100%
      const to = Math.floor(Math.random() * 6);         // 0 ~ 5%
      setProbability({ from, to });
    } catch (err) {
      console.error('Error:', err);
      if (err.message === 'Insufficient credits') {
        showToast("You don't have enough credits. Please subscribe.", 'error');
      } else if (err.message === 'Could not validate credentials, please login again') {
        showToast(err.message, 'error');
        navigate('/login');
      } else {
        showToast(err.message || 'Failed to submit', 'error');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopy = async () => {
    try {
      const tempElement = document.createElement('div');
      tempElement.innerHTML = outputHtml;
      const textToCopy = tempElement.textContent || tempElement.innerText;
      await navigator.clipboard.writeText(textToCopy);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
      showToast('Failed to copy text', 'error');
    }
  };

  const isSubmitDisabled = isLoading || wordCount < 10 || wordCount > 500;

  return (
    <>
      <Helmet>
        <title>Bypass AI Detectors | Humanize AI Text | Remove AI Fingerprint | Bypass Turnitin</title>
        <meta name="description" content="Bypass Turnitin and other AI detectors. Our tool helps humanize AI-generated content and remove AI fingerprints to pass detection systems with higher human scores." />
        <meta name="keywords" content="bypass AI detectors, humanize AI, Turnitin, remove AI fingerprint, AI text humanizer, pass AI detection" />
        <link rel="canonical" href="/rephrase" />
      </Helmet>
      
      <main className="min-h-screen bg-gradient-to-br from-teal-50 to-blue-50 flex flex-col p-0">
        <div className="max-w-7xl mx-auto w-full flex-grow flex flex-col space-y-8">
          <section className="mb-6">
            <h1 className="text-2xl font-bold text-center text-gray-800 mb-2">Bypass AI Detectors & Humanize AI Text</h1>
            {/* <p className="text-center text-gray-600 max-w-2xl mx-auto">
              Our advanced tool removes AI fingerprints from your content, helping you bypass Turnitin and other AI detection systems while maintaining the original meaning.
            </p> */}
          </section>
          
          {/* 编辑器区域 */}
          <section className="grid lg:grid-cols-2 gap-8 flex-grow">
            {/* 输入区域 */}
            <article className="bg-white rounded-2xl p-6 shadow-lg lg:h-[550px] flex flex-col">
              <h2 className="text-xl font-semibold mb-3 text-gray-700">AI-Generated Content</h2>
              <textarea
                className="flex-1 w-full p-6 text-lg border-none rounded-xl focus:ring-2 focus:ring-teal-300 resize-none bg-gray-50/50 overflow-auto"
                placeholder="Paste your AI-generated content here to humanize and bypass AI detection..."
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                style={{ minHeight: '400px' }}
                aria-label="AI text to humanize"
              />
              <div className="mt-4 flex justify-between items-center text-sm">
                <div className="text-gray-500">
                  {wordCount > 500 ? (
                    <span className="text-red-500">
                      Minimum 10 words - Current: {wordCount} (Maximum 500)
                    </span>
                  ) : (
                    `Minimum 10 words - Current: ${wordCount}`
                  )}
                </div>
                <button
                  className="bg-gradient-to-r from-teal-400 to-blue-400 text-white px-6 py-2 rounded-full hover:shadow-lg transition-all disabled:opacity-50"
                  onClick={handleSubmit}
                  disabled={isSubmitDisabled}
                  aria-label="Remove AI fingerprint"
                >
                  {isLoading ? (
                    <span className="flex items-center">
                      <span className="animate-spin mr-2">🌀</span> 
                      Humanizing AI Text...
                    </span>
                  ) : (
                    <span className="flex items-center">
                      <LightningChargeFill className="mr-2" />
                      Bypass AI Detection
                    </span>
                  )}
                </button>
              </div>
            </article>

            {/* 输出区域 */}
            <article className="bg-white rounded-2xl p-6 shadow-lg lg:h-[550px] flex flex-col">
              <h2 className="text-xl font-semibold mb-3 text-gray-700">Humanized Content</h2>
              {/* 利用 flex-1 和 relative + absolute 定位保证内部区域固定高度且可滚动 */}
              <div className="flex-1 relative overflow-hidden">
                <div
                  className="absolute inset-0 p-6 overflow-y-auto bg-gray-50/50 rounded-xl prose max-w-none"
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(outputHtml) }}
                  aria-label="Humanized content that bypasses AI detection"
                />
                {outputHtml && (
                  <button
                    onClick={handleCopy}
                    className={`absolute bottom-4 right-4 flex items-center px-4 py-2 rounded-full transition-all ${
                      copySuccess 
                        ? 'bg-green-500 text-white'
                        : 'bg-gradient-to-r from-teal-100 to-blue-100 text-gray-600 hover:shadow-md'
                    }`}
                    aria-label="Copy humanized content"
                  >
                    <Clipboard className="mr-2" />
                    {copySuccess ? 'Copied!' : 'Copy Content'}
                  </button>
                )}
              </div>
              {/* 仅在点击 Delete AI 后更新的概率显示 */}
              {outputHtml && probability && (
                <div className="mt-4 p-4 bg-teal-50 rounded-lg text-sm text-teal-700">
                  <PatchCheck className="inline mr-2 text-teal-500" />
                  <strong>Turnitin® AI detection reduced from {probability.from}% to {probability.to}%</strong>
                </div>
              )}
            </article>
          </section>

          {/* Add an informational section about the tool */}
          { (
            <section className="bg-white rounded-xl p-6 shadow-md mt-8">
              <h2 className="text-2xl font-bold mb-4 text-gray-800">How Our AI Bypass Tool Works</h2>
              <div className="grid md:grid-cols-3 gap-6">
                <div className="p-4 border border-teal-100 rounded-lg">
                  <h3 className="font-semibold text-lg mb-2">1. Removes AI Patterns</h3>
                  <p>Our advanced algorithm identifies and eliminates patterns that AI detectors like Turnitin use to flag content as AI-generated.</p>
                </div>
                <div className="p-4 border border-teal-100 rounded-lg">
                  <h3 className="font-semibold text-lg mb-2">2. Humanizes Text</h3>
                  <p>We introduce natural human writing variations while preserving your original meaning and intent.</p>
                </div>
                <div className="p-4 border border-teal-100 rounded-lg">
                  <h3 className="font-semibold text-lg mb-2">3. Passes AI Detection</h3>
                  <p>The result is content that maintains quality but successfully bypasses AI detection systems with high human scores.</p>
                </div>
              </div>
            </section>
          )}
        </div>
      </main>
    </>
  );
}

export default RephraseApp;