import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ShieldShaded } from 'react-bootstrap-icons';
import BuyButton from './BuyButton';
import { getUserInfo } from './api';
import CountdownTimer from './CountdownTimer';

const PricingPage = () => {
  const { t, i18n } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const verifyUser = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setIsLoading(false);
        return;
      }
      try {
        const info = await getUserInfo(token);
        setUserInfo(info);
      } catch (error) {
        console.error('Failed to verify user:', error);
        localStorage.removeItem('token');
        localStorage.removeItem('userInfo');
      } finally {
        setIsLoading(false);
      }
    };
    verifyUser();
  }, []);

  const handleBuy = (stripeLink) => {
    if (!userInfo) {
      navigate('/login');
    } else {
      const email = encodeURIComponent(userInfo.email);
      const linkWithEmail = `${stripeLink}?prefilled_email=${email}`;
      window.open(linkWithEmail, '_blank');
    }
  };

  const plans = [
    {
      name: "Spacial Deal",
      price: "$9.99/month",
      details: [
        "🚀 Unlimited Remove AI Content",
        "💫 Full Access to Premium Features",
        "🔄 Cancel Anytime",
        "📱 All Platform Support",
        "🎯 Undetectable by AI Detection Tools"
      ],
      stripeLink: "https://buy.stripe.com/3csaI73Q4eStbkc3cD"
    }
  ];

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-teal-50 to-blue-50 flex items-center justify-center">
        <div className="animate-pulse text-gray-500">{t('pricing.loading')}</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-teal-50 to-blue-50">
      <div className="max-w-7xl mx-auto px-6 py-12">
        <div className="flex flex-col lg:flex-row items-center lg:items-start justify-between gap-12">
          {/* Left Column - Product Description */}
          <div className="lg:w-1/2 space-y-8">
            <div className="space-y-4">
              <h1 className="text-4xl lg:text-5xl font-bold">
                <span className="bg-gradient-to-r from-teal-500 to-blue-400 bg-clip-text text-transparent">
                  Bypass AI Detectors
                </span>
              </h1>
              <h2 className="text-3xl lg:text-4xl font-bold text-gray-800">
                Humanize Your AI Content
              </h2>
              <p className="text-xl text-gray-600 mt-4 leading-relaxed">
                Transform AI-generated text into 100% human-like content that passes Turnitin® and other AI detection tools.
              </p>
            </div>

            {/* Key Features */}
            <div className="space-y-6 mt-8">
              <h3 className="text-2xl font-semibold text-gray-800">Why Choose Us</h3>
              <ul className="space-y-6">
                <li className="flex items-start space-x-4">
                  <div className="flex-shrink-0 p-2 bg-teal-50 rounded-lg">
                    <svg className="w-6 h-6 text-teal-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                    </svg>
                  </div>
                  <div>
                    <h4 className="text-lg font-semibold text-gray-800">Advanced AI Detection Bypass</h4>
                    <p className="text-gray-600">Consistently pass AI content detectors including Turnitin®, GPTZero, and more</p>
                  </div>
                </li>
                <li className="flex items-start space-x-4">
                  <div className="flex-shrink-0 p-2 bg-blue-50 rounded-lg">
                    <svg className="w-6 h-6 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                    </svg>
                  </div>
                  <div>
                    <h4 className="text-lg font-semibold text-gray-800">Human-Like Content</h4>
                    <p className="text-gray-600">Transform AI text into natural, flowing content that reads like human writing</p>
                  </div>
                </li>
                <li className="flex items-start space-x-4">
                  <div className="flex-shrink-0 p-2 bg-purple-50 rounded-lg">
                    <svg className="w-6 h-6 text-purple-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
                    </svg>
                  </div>
                  <div>
                    <h4 className="text-lg font-semibold text-gray-800">Remove AI Traces</h4>
                    <p className="text-gray-600">Eliminate all detectable patterns of AI-generated content</p>
                  </div>
                </li>
                <li className="flex items-start space-x-4">
                  <div className="flex-shrink-0 p-2 bg-rose-50 rounded-lg">
                    <svg className="w-6 h-6 text-rose-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                    </svg>
                  </div>
                  <div>
                    <h4 className="text-lg font-semibold text-gray-800">Academic Safe</h4>
                    <p className="text-gray-600">Perfect for academic papers, essays, and assignments that require Turnitin® checks</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          {/* Right Column - Pricing Card */}
          <div className="lg:w-1/2 flex justify-center">
            <div 
              className="bg-white/90 backdrop-blur-sm rounded-2xl p-8 shadow-xl border border-gray-100/50 relative transition-all hover:transform hover:-translate-y-2 max-w-md w-full"
            >
              <div className="absolute top-0 right-0 bg-gradient-to-r from-rose-400 to-pink-500 text-white px-3 py-1 rounded-tr-2xl rounded-bl-lg text-sm font-semibold">
                Limited Time Offer
              </div>
              
              <div className="space-y-6">
                <h2 className="text-2xl font-bold text-gray-800">Discount Plan</h2>
                
                <div className="bg-gradient-to-r from-teal-100 to-blue-100 p-4 rounded-xl">
                  <div className="flex items-center justify-center gap-3">
                    <p className="text-3xl font-bold bg-gradient-to-r from-teal-600 to-blue-500 bg-clip-text text-transparent">
                      $9.99
                    </p>
                    <span className="text-lg text-gray-500 line-through">
                      $39.99
                    </span>
                    <span className="bg-red-500 text-white text-sm px-2 py-1 rounded-full">
                      80% OFF
                    </span>
                  </div>
                  <p className="text-center text-gray-600 mt-2">per month</p>
                </div>

                <div className="border-t border-b border-gray-100 py-4">
                  <p className="text-center text-sm text-gray-600 mb-2">Special offer ends in:</p>
                  <CountdownTimer />
                </div>

                <ul className="space-y-4">
                  {[
                    "🚀 Unlimited Remove AI Content",
                    "💫 Full Access to Premium Features",
                    "🔄 Cancel Anytime",
                    "📱 All Platform Support",
                    "🎯 Undetectable by AI Detection Tools"
                  ].map((detail, detailIndex) => (
                    <li key={detailIndex} className="flex items-start text-gray-600">
                      <svg 
                        className="w-5 h-5 mr-3 text-teal-500 flex-shrink-0 mt-1" 
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                      <span>{detail}</span>
                    </li>
                  ))}
                </ul>

                <BuyButton 
                  plan={plans[0]} 
                  userInfo={userInfo} 
                  handleBuy={handleBuy}
                  className="w-full mt-auto bg-gradient-to-r from-teal-500 to-blue-500 hover:from-teal-600 hover:to-blue-600 text-white font-semibold py-3 px-6 rounded-xl transition-all"
                >
                  Subscribe Now
                </BuyButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;