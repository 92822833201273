import React, { useState, useEffect } from 'react';

const CountdownTimer = () => {
  const [timeLeft, setTimeLeft] = useState(() => {
    // 计算距离最近的偶数天数0点的时间
    const now = new Date();
    const target = new Date();
    
    // 设置目标时间为下一个偶数天的0点
    target.setHours(0, 0, 0, 0); // 设置为当天0点
    const daysUntilNext = (2 - (target.getDate() % 2)) % 2; // 计算到下一个偶数天的天数
    target.setDate(target.getDate() + daysUntilNext);
    
    // 如果当前已经过了今天的0点，且今天是偶数天，就设置为下一个偶数天
    if (daysUntilNext === 0 && now > target) {
      target.setDate(target.getDate() + 2);
    }

    // 计算时间差
    const diff = target - now;
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds
    };
  });

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const target = new Date();
      
      // 设置目标时间为下一个偶数天的0点
      target.setHours(0, 0, 0, 0);
      const daysUntilNext = (2 - (target.getDate() % 2)) % 2;
      target.setDate(target.getDate() + daysUntilNext);
      
      // 如果当前已经过了今天的0点，且今天是偶数天，就设置为下一个偶数天
      if (daysUntilNext === 0 && now > target) {
        target.setDate(target.getDate() + 2);
      }

      // 计算时间差
      const diff = target - now;
      if (diff <= 0) {
        // 如果倒计时结束，重新设置为下一个偶数天
        target.setDate(target.getDate() + 2);
      }

      setTimeLeft({
        days: Math.floor(diff / (1000 * 60 * 60 * 24)),
        hours: Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((diff % (1000 * 60)) / 1000)
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="flex items-center justify-center space-x-4 text-sm">
      <div className="text-center">
        <div className="bg-gray-800 text-white rounded-lg px-2 py-1 font-mono">
          {timeLeft.days}
        </div>
        <div className="text-xs mt-1">Days</div>
      </div>
      <div className="text-center">
        <div className="bg-gray-800 text-white rounded-lg px-2 py-1 font-mono">
          {timeLeft.hours.toString().padStart(2, '0')}
        </div>
        <div className="text-xs mt-1">Hours</div>
      </div>
      <div className="text-center">
        <div className="bg-gray-800 text-white rounded-lg px-2 py-1 font-mono">
          {timeLeft.minutes.toString().padStart(2, '0')}
        </div>
        <div className="text-xs mt-1">Mins</div>
      </div>
      <div className="text-center">
        <div className="bg-gray-800 text-white rounded-lg px-2 py-1 font-mono">
          {timeLeft.seconds.toString().padStart(2, '0')}
        </div>
        <div className="text-xs mt-1">Secs</div>
      </div>
    </div>
  );
};

export default CountdownTimer; 