import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login, getUserInfo } from './api';
import EmailInput from './EmailInput';
import PasswordInput from './PasswordInput';
import { ShieldShaded } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

const LoginPage = ({ setUserInfo, showToast }) => {
  const { t, i18n } = useTranslation();
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = await login(email, password);
      localStorage.setItem('token', data.access_token);
      const userInfo = await getUserInfo(data.access_token);
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      setUserInfo(userInfo);
      navigate('/deleteai');
    } catch (error) {
      showToast(error.message || t('login.error'), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-teal-50 to-blue-50">

      {/* 登录表单 */}
      <div className="max-w-md mx-auto p-8 mt-12">
        <div className="bg-white/90 backdrop-blur-sm rounded-2xl p-8 shadow-xl border border-gray-100/50">
          <h2 className="text-3xl font-bold text-gray-800 mb-8 text-center">
            {t('login.title')}
          </h2>
          
          <form onSubmit={handleSubmit} className="space-y-6">
            <EmailInput 
              email={email} 
              setEmail={setEmail} 
              disabled={isLoading}
              labelStyle="text-gray-700 font-medium"
              inputStyle="w-full px-4 py-3 rounded-lg bg-gray-50 border border-gray-200 focus:ring-2 focus:ring-teal-400 focus:border-transparent"
            />
            
            <PasswordInput
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              show={showPassword}
              setShow={setShowPassword}
              disabled={isLoading}
              labelStyle="text-gray-700 font-medium"
              inputStyle="w-full px-4 py-3 rounded-lg bg-gray-50 border border-gray-200 focus:ring-2 focus:ring-teal-400 focus:border-transparent"
            />

            <button 
              type="submit" 
              className="w-full bg-gradient-to-r from-teal-400 to-blue-400 text-white py-3.5 rounded-xl font-semibold hover:shadow-lg transition-all disabled:opacity-70 disabled:cursor-not-allowed"
              disabled={isLoading}
            >
              {isLoading ? t('login.processing') : t('login.button')}
            </button>
          </form>

          <p className="mt-8 text-center text-gray-600">
            {t('login.noAccount')}{' '}
            <button
              onClick={() => navigate('/register')}
              className="bg-gradient-to-r from-teal-500 to-blue-400 bg-clip-text text-transparent font-semibold hover:opacity-80 transition-opacity"
            >
              {t('login.registerHere')}
            </button>
          </p>
        </div>
      </div>

    </div>
  );
};

export default LoginPage;