// components/Toast.jsx
import { PatchCheck } from 'react-bootstrap-icons';

const Toast = ({ isOpen, message, type, onClose, className }) => {
  const typeStyles = {
    success: 'bg-green-50 border-green-400 text-green-700',
    error: 'bg-red-50 border-red-400 text-red-700',
    info: 'bg-blue-50 border-blue-400 text-blue-700'
  };

  return (
    <div 
      className={`${className} ${typeStyles[type]} border-l-4 p-4 rounded-lg shadow-lg transition-opacity duration-300 ${
        isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
      }`}
    >
      <div className="flex items-center">
        <PatchCheck className="h-5 w-5 mr-3" />
        <span className="font-medium">{message}</span>
      </div>
    </div>
  );
};

export default Toast;