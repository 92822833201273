import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { register } from './api';
import EmailInput from './EmailInput';
import PasswordInput from './PasswordInput';
import Alert from './Alert';
import { ShieldShaded } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

const RegisterPage = () => {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ message: '', type: 'error' });
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/deleteai');
    }
  }, [navigate]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setAlert({ message: t('register.passwordMismatch'), type: 'error' });
      return;
    }
    setIsLoading(true);
    try {
      await register(email, password);
      navigate('/verify-email', { state: { email } });
    } catch (error) {
      setAlert({ message: error.message || t('register.error'), type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-teal-50 to-blue-50">
      {/* 注册表单 */}
      <div className="max-w-md mx-auto p-8 mt-12">
        <div className="bg-white/90 backdrop-blur-sm rounded-2xl p-8 shadow-xl border border-gray-100/50">
          <h2 className="text-3xl font-bold text-gray-800 mb-8 text-center">
            {t('register.title')}
          </h2>

          {alert.message && <Alert message={alert.message} type={alert.type} />}

          <form onSubmit={handleSubmit} className="space-y-6">
            <EmailInput 
              email={email} 
              setEmail={setEmail} 
              disabled={isLoading}
              labelStyle="text-gray-700 font-medium"
              inputStyle="w-full px-4 py-3 rounded-lg bg-gray-50 border border-gray-200 focus:ring-2 focus:ring-teal-400 focus:border-transparent"
            />

            <PasswordInput
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              show={showPassword}
              setShow={setShowPassword}
              disabled={isLoading}
              label={t('register.password')}
              labelStyle="text-gray-700 font-medium"
              inputStyle="w-full px-4 py-3 rounded-lg bg-gray-50 border border-gray-200 focus:ring-2 focus:ring-teal-400 focus:border-transparent"
            />

            <PasswordInput
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              show={showConfirmPassword}
              setShow={setShowConfirmPassword}
              disabled={isLoading}
              label={t('register.confirmPassword')}
              labelStyle="text-gray-700 font-medium"
              inputStyle="w-full px-4 py-3 rounded-lg bg-gray-50 border border-gray-200 focus:ring-2 focus:ring-teal-400 focus:border-transparent"
            />

            <button 
              type="submit" 
              className="w-full bg-gradient-to-r from-teal-400 to-blue-400 text-white py-3.5 rounded-xl font-semibold hover:shadow-lg transition-all disabled:opacity-70 disabled:cursor-not-allowed"
              disabled={isLoading}
            >
              {isLoading ? t('register.processing') : t('register.button')}
            </button>
          </form>

          <p className="mt-8 text-center text-gray-600">
            {t('register.haveAccount')}{' '}
            <button
              onClick={() => navigate('/login')}
              className="bg-gradient-to-r from-teal-500 to-blue-400 bg-clip-text text-transparent font-semibold hover:opacity-80 transition-opacity"
            >
              {t('register.loginHere')}
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;